import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import { buildBlocks } from "../services/blockGenerator"
import { ButtonLink } from "@/components/reusable/Button"
import {
  Newsletter,
  NewsletterTemplate,
} from "@/components/blocks/acf/AcfNewsletterSignupBlock"
import { Heading } from "@/components/blocks/core/CoreHeadingBlock"

// TODO: Update types
type PostProps = {
  data: {
    wpPost: {
      Meta: {
        title: string
      }
      title: string
      date: string
      categories: any
      featuredImage: any
      blocks: any
      floatingButton: {
        showButton: boolean
        buttonLink?: {
          url: string
          title: string
        }
      }
    }
    wp: {
      themeOptions: any
    }
  }
  pageContext: {
    pageTitle?: string
    date?: string
    cats?: any
    featuredImage?: any
    pagePath: string
  }
}

export default function Post({ data, pageContext }: PostProps) {
  pageContext.pageTitle = data.wpPost.Meta.title
    ? data.wpPost.Meta.title
    : data.wpPost.title
  pageContext.date = data.wpPost.date
  pageContext.cats = data.wpPost.categories
  pageContext.featuredImage = data.wpPost.featuredImage

  const generatedBlocks = buildBlocks(data.wpPost.blocks, {}, pageContext)

  return (
    <Layout
      meta={data.wpPost.Meta}
      path={pageContext.pagePath}
      title={data.wpPost.title}
      themeOptions={data.wp.themeOptions}
    >
      {generatedBlocks}
      {data.wpPost.floatingButton.showButton && (
        <div
          className={`floating-button fixed bottom-4 right-6 z-10 [&_.brew-button]:m-0`}
        >
          <ButtonLink
            className={``}
            link={data.wpPost.floatingButton?.buttonLink?.url}
            variant={"primary"}
          >
            {data.wpPost.floatingButton?.buttonLink?.title}
          </ButtonLink>
        </div>
      )}
      <Newsletter />
    </Layout>
  )
}

export const query = graphql`
  query ($id: String) {
    wpPost(id: { eq: $id }) {
      id
      slug
      title
      date(formatString: "DD MMMM YYYY")
      categories {
        nodes {
          name
          slug
        }
      }
      featuredImage {
        node {
          mediaItemUrl
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                aspectRatio: 1
                formats: [AUTO, WEBP]
                quality: 100
                transformOptions: { fit: COVER, cropFocus: CENTER }
              )
            }
          }
        }
      }
      Meta {
        fieldGroupName
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
        twitterImage {
          uri
        }
        ogImage {
          uri
        }
      }
      blocks {
        ...CoreBlock
        ...CoreFields
        innerBlocks {
          ...CoreBlock
          ...CoreFields
          innerBlocks {
            ...CoreBlock
            ...CoreFields
            innerBlocks {
              ...CoreBlock
              ...CoreFields
              innerBlocks {
                ...CoreBlock
                ...CoreFields
              }
            }
          }
        }
      }
      floatingButton {
        showButton
        buttonLink {
          title
          url
        }
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`
